import { gql } from "@apollo/client";

export const USER_FOLLOW_MUTATION = gql`
  mutation FeedUserFollow($id: ID!) {
    feedUserFollow(id: $id) {
      id
      byCurrentUser
      followedAt
    }
  }
`;

export const USER_UNFOLLOW_MUTATION = gql`
  mutation FeedUserUnfollow($id: ID!) {
    feedUserUnfollow(id: $id) {
      id
      byCurrentUser
      followedAt
    }
  }
`;

export const USER_REFER_PROSPECT_MUTATION = gql`
  mutation referProspect($input: ReferProspectInput!) {
    referProspect(input: $input) {
      id
    }
  }
`;

export const USER_ORIENTATION_DISMISS_MUTATION = gql`
  mutation orientationDismiss {
    orientationDismiss
  }
`;

export const USER_ORIENTATION_MOBILE_APP_SKIP_MUTATION = gql`
  mutation orientationMobileAppSkip {
    orientationMobileAppSkip
  }
`;
